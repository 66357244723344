<template>
  <div class="content03">
    <h2>新規登録</h2>
    <div class="wrap">
      <form action="">
        <dl>
          <dt>ニックネーム</dt>
          <dd>
            <input type="text" class="name" id="name" v-model="user.name" />
          </dd>
          <span v-show="!result.name" class="note">{{
            result.nameMessage
          }}</span>
          <span v-if="valid.name" class="note"
            >ニックネームを入力してください</span
          >
        </dl>
        <dl>
          <dt>メールアドレス</dt>
          <dd>
            <input type="text" class="mail" id="mail" v-model="user.mail" />
          </dd>
          <span v-show="!result.mailResult" class="note">{{
            result.mailMessage
          }}</span>
          <span v-if="valid.mail" class="note"
            >メールアドレスの形式で入力してください</span
          >
        </dl>
        <dl>
          <dt>パスワード</dt>
          <dd>
            <input
              type="password"
              class="pass"
              id="pass"
              v-model="user.pass"
            /><span class="sub_txt"
              >※半角のアルファベットと数字の組合せで8文字以上でご登録下さい。例：Mog012345678<br />
              ※標準の記号（!@#%^&*）や空白（スペース）は使用できません。</span
            ><br />
            <span v-if="valid.pass" class="note"
              >パスワードは半角英数字8文字以上で入力してください</span
            >
          </dd>
        </dl>
        <dl>
          <dt>パスワード（確認用）</dt>
          <dd>
            <input
              type="password"
              class="pass"
              id="pass02"
              v-model="user.passConfirm"
            />
            <span v-if="valid.passConfirm" class="note"
              >パスワードが一致しません</span
            >
          </dd>
        </dl>
        <dl>
          <dt>電話投票加入者番号</dt>
          <dd>
            <input
              type="text"
              class="tel_number"
              id="tel_number"
              v-model="user.tel_number"
            />
            <span class="sub_txt"
              >※電話投票加入者番号とはテレボートの会員登録時に発行される6桁または8桁の数字です。<br />
              ※電話投票加入者番号6桁の方は頭に２桁00を追加した8桁の数字をご入力ください。</span
            >
          </dd>
          <span v-if="valid.tel_number" class="note">
            半角数字8桁で入力してください。
          </span>
        </dl>
        <div class="radio_box">
          <p class="txt01">
            MOGからお得な情報などメールにてお送りしてもよろしいですか？
          </p>
          <div class="radio">
            <input
              id="yes"
              type="radio"
              value="1"
              name="mailmagazine"
              v-model="user.magazine"
              checked
            />
            <label for="yes">はい</label>
            <input
              id="no"
              type="radio"
              value="0"
              name="mailmagazine"
              v-model="user.magazine"
            />
            <label for="no">いいえ</label>
          </div>
          <p class="txt02">
            ※info@mog.comからのメールを受け取れるよう<br />迷惑メールフィルターのご確認をお願いします。
          </p>
        </div>
        <p class="submit">
          <input
            type="submit"
            value="登&emsp;録"
            v-on:click.prevent="regist()"
          />
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import Valid from "@/mixins/validate";
export default {
  name: "Registration",
  data: function () {
    return {
      user: {
        name: null,
        mail: null,
        tel_number: null,
        pass: null,
        passConfirm: null,
        magazine: 1,
      },
      valid: {},
      result: {},
    };
  },
  mixins: [Valid],
  methods: {
    regist() {
      if (this.isInValidEmail && this.isInValidPass) {
        this.$store.dispatch("regist", this.user).then((response) => {
          this.result = {};
          if (response.data.result) {
            this.$router.push("/mypage");
          } else {
            if (response.data.nickname == 1) {
              this.result.name = false;
              this.result.nameMessage =
                "既に登録されているニックネームになります。";
            }
            if (response.data.email == 1) {
              this.result.mail = false;
              this.result.mailMessage =
                "既に登録されているメールアドレスになります。";
            }
          }
        });
      }
      !this.isInValidName
        ? (this.valid.name = true)
        : (this.valid.name = false);
      !this.isInValidEmail
        ? (this.valid.mail = true)
        : (this.valid.mail = false);
      !this.isInValidPass
        ? (this.valid.pass = true)
        : (this.valid.pass = false);
      !this.isInValidPassConfirm
        ? (this.valid.passConfirm = true)
        : (this.valid.passConfirm = false);
      !this.isInValidTelnumber
        ? (this.valid.tel_number = true)
        : (this.valid.tel_number = false);
      !this.isInValidTelnumber
        ? (this.valid.tel_number = true)
        : (this.valid.tel_number = false);
      !this.isInValidNumCount
        ? (this.valid.tel_number = true)
        : (this.valid.tel_number = false);
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
span.note {
  font-size: 12px;
  color: rgb(255, 0, 0);
}
</style>
<style lang="css" src="@/assets/css/style_form_page.css" scoped></style>
<style lang="css" src="@/assets/css/style_form_parts.css" scoped></style>
